<template>
  <section class="modal--container auth--container forget--pass-container">
    <div class="title--text hide--in-mobile">
      {{ $t('loginRegister.forgot') }}
    </div>
    <form @submit.prevent="forgetPass" id="forgetPassForm">
      <div class="auth--input mb-3" :class="{ 'has-error': validation.hasError('email') }">
        <v-text-field
          label="Email"
          outlined
          :hide-details="true"
          v-model="email"
          name="email"
          class="basic--input"
        ></v-text-field>
        <span class="val-error" v-if="validation.hasError('email')">{{
          validation.firstError('email')
        }}</span>
      </div>
      <hr class="separator" />
      <button :disabled="isLoading" type="submit" class="btn btn-primary main--btn">
        {{ $t('loginRegister.sendEmail') }}
      </button>
    </form>
  </section>
</template>

<script>
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';

export default {
  mixins: [HelperMixin],
  data() {
    return {
      email: null,
      isLoading: false,
    };
  },
  validators: {
    email: {
      cache: true,
      debounce: 250,
      validator: function (value) {
        return Validator.value(value)
          .required(this.$i18n.t('errors.loginRegister.email.required'))
          .email(this.$i18n.t('errors.loginRegister.invalid'))
          .maxLength(255, this.$i18n.t('errors.loginRegister.password.max', { maxChar: 255 }))
          .custom(async () => {
            const { data } = await this.$store.dispatch('global/checkEmail', value);
            if (!data.exist) {
              return this.$i18n.t('errors.loginRegister.email.notRegistered');
            }
          });
      },
    },
  },
  methods: {
    showModalSuccess() {
      this.$modal.hide('modal-forget-pass');
      this.$modal.show('modal-forget-pass-confirm');
    },
    async forgetPass() {
      try {
        this.isLoading = true;
        const isValid = await this.$validate();
        if (isValid) {
          // eslint-disable-next-line no-unused-vars
          const { data } = await this.$store.dispatch('global/forgotPassword', {
            email: this.cleanInput(this.email),
          });
          this.showModalSuccess();
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
