<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper hide--in-desktop hide--in-tablet">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('loginRegister.forgot') }}
      </div>
      <forget-pass></forget-pass>
      <button type="button" class="close hide--in-mobile" @click="close">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import ForgetPass from '@/components/auth/forget-pass';
export default {
  components: {
    ForgetPass,
  },
  data: () => ({
    modalName: 'modal-forget-pass',
  }),
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
  },
};
</script>
